import { createAction } from '@reduxjs/toolkit';

export const createCampaignInit = createAction(
  '[Campaigns] Create Campaign Init'
);
export const createCampaignSuccess = createAction(
  '[Campaigns] Create Campaign Success'
);
export const createCampaignFail = createAction(
  '[Campaigns] Create Campaign Fail'
);

export const deleteCampaignInit = createAction(
  '[Campaigns] Delete Campaign Init'
);
export const deleteCampaignSuccess = createAction(
  '[Campaigns] Delete Campaign Success'
);
export const deleteCampaignFail = createAction(
  '[Campaigns] Delete Campaign Fail'
);

export const editCampaignInit = createAction('[Campaigns] Edit Campaign Init');
export const editCampaignSuccess = createAction(
  '[Campaigns] Edit Campaign Success'
);
export const editCampaignFail = createAction('[Campaigns] Edit Campaign Fail');

export const fetchUnfinishedCampaignInit = createAction(
  '[Campaigns] Fetch Unfinished Campaign Init'
);
export const fetchUnfinishedCampaignSuccess = createAction(
  '[Campaigns] Fetch Unfinished Campaign Success'
);
export const fetchUnfinishedCampaignFail = createAction(
  '[Campaigns] Fetch Unfinished Campaign Fail'
);

export const addOffersInit = createAction('[Campaigns] Add Offers Init');
export const addOffersSuccess = createAction('[Campaigns] Add Offers Success');
export const addOffersFail = createAction('[Campaigns] Add Offers Fail');

export const finishedCampaignInit = createAction(
  '[Campaigns] Finished Campaign Init'
);
export const finishedCampaignSuccess = createAction(
  '[Campaigns] Finished Campaign Success'
);
export const finishedCampaignFail = createAction(
  '[Campaigns] Finished Campaign Fail'
);

export const fetchAllPendingCampaignsInit = createAction(
  '[Campaigns] Fetch All Pending Campaigns Init'
);
export const fetchAllPendingCampaignsSuccess = createAction(
  '[Campaigns] Fetch All Pending Campaigns Success'
);
export const fetchAllPendingCampaignsFail = createAction(
  '[Campaigns] Fetch All Pending Campaigns Fail'
);

export const fetchAllCurrentCampaignsInit = createAction(
  '[Campaigns] Fetch All Current Campaigns Init'
);
export const fetchAllCurrentCampaignsSuccess = createAction(
  '[Campaigns] Fetch All Current Campaigns Success'
);
export const fetchAllCurrentCampaignsFail = createAction(
  '[Campaigns] Fetch All Current Campaigns Fail'
);

export const fetchAllFinishedCampaignsInit = createAction(
  '[Campaigns] Fetch All Finished Campaigns Init'
);
export const fetchAllFinishedCampaignsSuccess = createAction(
  '[Campaigns] Fetch All Finished Campaigns Success'
);
export const fetchAllFinishedCampaignsFail = createAction(
  '[Campaigns] Fetch All Finished Campaigns Fail'
);

export const fetchExistingCampaignsInit = createAction(
  '[Campaigns] Fetch Existing Campaigns Init'
);
export const fetchExistingCampaignsSuccess = createAction(
  '[Campaigns] Fetch Existing Campaigns Success'
);
export const fetchExistingCampaignsFail = createAction(
  '[Campaigns] Fetch Existing Campaigns Fail'
);

export const fetchCampaignCategoriesInit = createAction(
  '[Campaigns] Fetch Campaign Categories Init'
);
export const fetchCampaignCategoriesSuccess = createAction(
  '[Campaigns] Fetch Campaign Categories Success'
);
export const fetchCampaignCategoriesFail = createAction(
  '[Campaigns] Fetch Campaign Categories Fail'
);

export const clearCampaign = createAction('[Campaigns] Clear Campaign');

export const fetchCampaignByIdInit = createAction(
  '[Campaigns] Fetch Campaign By Id Init'
);
export const fetchCampaignByIdSuccess = createAction(
  '[Campaigns] Fetch Campaign By Id Success'
);
export const fetchCampaignByIdFail = createAction(
  '[Campaigns] Fetch Campaign By Id Fail'
);

export const fetchCampaignsPendingOffersInit = createAction(
  '[Campaigns] Fetch Campaigns Pending Offers Init'
);
export const fetchCampaignsPendingOffersSuccess = createAction(
  '[Campaigns] Fetch Campaigns Pending Offers Success'
);
export const fetchCampaignsPendingOffersFail = createAction(
  '[Campaigns] Fetch Campaigns Pending Offers Fail'
);

export const fetchCampaignsPodcastsDataInit = createAction(
  '[Campaigns] Fetch Campaigns Podcasts Data Init'
);
export const fetchCampaignsPodcastsDataSuccess = createAction(
  '[Campaigns] Fetch Campaigns Podcasts Data Success'
);
export const fetchCampaignsPodcastsDataFail = createAction(
  '[Campaigns] Fetch Campaigns Podcasts Data Fail'
);

export const fetchNewCampaignEstimationsInit = createAction(
  '[Campaigns] Fetch New Campaign Estimations Init'
);
export const fetchNewCampaignEstimationsSuccess = createAction(
  '[Campaigns] Fetch New Campaign Estimations Success'
);
export const fetchNewCampaignEstimationsFail = createAction(
  '[Campaigns] Fetch New Campaign Estimations Fail'
);

export const sendAdCopyInit = createAction('[Campaigns] Send Ad Copy Init');
export const sendAdCopySuccess = createAction(
  '[Campaigns] Send Ad Copy Success'
);
export const sendAdCopyFail = createAction('[Campaigns] Send Ad Copy Fail');

export const updateCampaignsTab = createAction(
  '[Campaigns] Update Campaigns Tab'
);

export const clearEstimationsFilters = createAction(
  '[Campaigns] Clear Estimations Filters'
);
export const editTalkingPointsInit = createAction(
  '[Campaigns] Edit Talking Points Init'
);
export const editTalkingPointsSuccess = createAction(
  '[Campaigns] Edit Talking Points Success'
);
export const editTalkingPointsFail = createAction(
  '[Campaigns] Edit Talking Points Fail'
);
export const clearTalkingPoints = createAction(
  '[Campaigns] Clear Talking Points'
);
