import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Weight as BodyWeight,
} from 'components/Typography/Body';
import ExportCsvButton from 'components/Common/ExportCsvButton';
import { campaignHeaders, offerHeaders } from 'utils/Campaign/csvExportHeaders';
import removeCamelCaseAndJoin from 'utils/functions/removeCamelCaseAndJoin';
import sentenceToSnakeCase from 'utils/functions/sentenceToSnakeCase';
import mapJsonEntries from 'utils/functions/mapJsonEntries';

import classes from './ExportCampaignModal.module.scss';

const ExportCampaignModal = ({ campaign, offers }) => {
  const [campaignData, setCampaignData] = useState([]);
  const [offersData, setOffersData] = useState([]);
  const [offersHeader, setOffersHeader] = useState([]);

  useEffect(() => {
    if (campaign)
      setCampaignData([
        {
          ...campaign,
          offersLength: offers.length,
          categories: mapJsonEntries(campaign.categories),
          countries: removeCamelCaseAndJoin(campaign.countries),
          age: removeCamelCaseAndJoin(campaign.age),
          adFormats: removeCamelCaseAndJoin(campaign.adFormats),
          adPlacements: removeCamelCaseAndJoin(campaign.adPlacements),
          gender: mapJsonEntries(campaign.gender),
          percentages: mapJsonEntries(campaign.percentages),
        },
      ]);
  }, [campaign]);

  useEffect(() => {
    if (offers) {
      setOffersHeader(offerHeaders);
      const parser = new DOMParser();
      const mappedOffers = offers.map((offer) => {
        let offerData = {
          title: offer.title,
          podcastName: offer.podcastName,
          adFormats: removeCamelCaseAndJoin(offer.adFormats),
          adPlacements: removeCamelCaseAndJoin(offer.adPlacements),
          episodes: offer.episodes,
          amount: offer.amount,
          impressions: offer.impressions,
        };
        if (offer.coverLetter.length > 0) {
          const headers = [
            ...offerHeaders,
            {
              label: 'Notes to the brand',
              key: 'Notes to the brand',
            },
          ];
          setOffersHeader(headers);
          offerData = {
            ...offerData,
            'Notes to the brand': offer.coverLetter
              ? parser.parseFromString(offer?.coverLetter, 'text/html').body
                  .firstChild.textContent
              : '',
          };
        }
        return offerData;
      });
      setOffersData(mappedOffers);
    }
  }, [offers]);

  const instructionText =
    offers?.length > 0
      ? 'You can download the campaign and/or offers data as a .CSV file.'
      : 'You can download the campaign data as a .CSV file.';

  return (
    <>
      <Body size={BodySize.M} weight={BodyWeight.Bold}>
        Export Campaign Information
      </Body>
      <Body size={BodySize.S} className={classes.subtext}>
        {instructionText}
      </Body>
      <div className={classes.buttonList}>
        {campaign && (
          <ExportCsvButton
            headers={campaignHeaders}
            data={campaignData}
            title="Download Campaign Data"
            filename={`${sentenceToSnakeCase(campaign.name)}-data.csv`}
          />
        )}
        {offers?.length > 0 && (
          <ExportCsvButton
            headers={offersHeader}
            data={offersData}
            title="Download Campaign Offers Data"
            filename={`${sentenceToSnakeCase(campaign.name)}-data-offers.csv`}
          />
        )}
      </div>
    </>
  );
};

ExportCampaignModal.propTypes = {
  campaign: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string.isRequired,
    categories: PropTypes.shape({}),
    countries: PropTypes.arrayOf(PropTypes.string).isRequired,
    age: PropTypes.arrayOf(PropTypes.string).isRequired,
    adFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
    adPlacements: PropTypes.arrayOf(PropTypes.string).isRequired,
    airingFrom: PropTypes.instanceOf(Date).isRequired,
    airingTo: PropTypes.instanceOf(Date).isRequired,
    gender: PropTypes.shape({}),
    percentages: PropTypes.shape({}),
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        budget: PropTypes.number,
        adTypes: PropTypes.arrayOf(PropTypes.string),
        offerStatus: PropTypes.string,
      })
    ),
  }),
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      podcastImageUrl: PropTypes.string,
      title: PropTypes.string,
      budget: PropTypes.number,
      adTypes: PropTypes.arrayOf(PropTypes.string),
      offerStatus: PropTypes.string,
    })
  ).isRequired,
};
ExportCampaignModal.defaultProps = {
  campaign: null,
};
export default ExportCampaignModal;
