import { serverTimestamp, Timestamp } from 'firebase/firestore';

import {
  createCampaignInit,
  createCampaignSuccess,
  createCampaignFail,
  deleteCampaignInit,
  deleteCampaignSuccess,
  deleteCampaignFail,
  editCampaignInit,
  editCampaignSuccess,
  editCampaignFail,
  fetchUnfinishedCampaignInit,
  fetchUnfinishedCampaignSuccess,
  fetchUnfinishedCampaignFail,
  addOffersInit,
  addOffersSuccess,
  addOffersFail,
  finishedCampaignInit,
  finishedCampaignSuccess,
  finishedCampaignFail,
  fetchAllCurrentCampaignsInit,
  fetchAllCurrentCampaignsSuccess,
  fetchAllCurrentCampaignsFail,
  fetchAllFinishedCampaignsInit,
  fetchAllFinishedCampaignsSuccess,
  fetchAllFinishedCampaignsFail,
  fetchExistingCampaignsInit,
  fetchExistingCampaignsSuccess,
  fetchExistingCampaignsFail,
  fetchCampaignByIdInit,
  fetchCampaignByIdSuccess,
  fetchCampaignByIdFail,
  clearCampaign,
  sendAdCopyInit,
  sendAdCopySuccess,
  sendAdCopyFail,
  fetchCampaignCategoriesInit,
  fetchCampaignCategoriesSuccess,
  fetchCampaignCategoriesFail,
  fetchCampaignsPendingOffersInit,
  fetchCampaignsPendingOffersSuccess,
  fetchCampaignsPendingOffersFail,
  fetchCampaignsPodcastsDataInit,
  fetchCampaignsPodcastsDataSuccess,
  fetchCampaignsPodcastsDataFail,
  updateCampaignsTab,
  fetchNewCampaignEstimationsSuccess,
  fetchNewCampaignEstimationsFail,
  fetchNewCampaignEstimationsInit,
  clearEstimationsFilters,
  editTalkingPointsInit,
  editTalkingPointsSuccess,
  editTalkingPointsFail,
  clearTalkingPoints,
  fetchAllPendingCampaignsInit,
  fetchAllPendingCampaignsSuccess,
  fetchAllPendingCampaignsFail,
} from 'state/actionCreators/campaigns';

import uploadCampaignImageService from 'services/campaigns/createCampaign/uploadImage';
import createCampaignService from 'services/campaigns/createCampaign';
import deleteCampaignService from 'services/campaigns/deleteCampaign';
import editCampaignService from 'services/campaigns/editCampaign';
import fetchUnfinishedCampaignService from 'services/campaigns/fetchUnfinishedCampaign';
import addOffersService from 'services/campaigns/addOffers';
import finishedCampaignService from 'services/campaigns/finishedCampaign';
import fetchAllCurrentCampaignsService from 'services/campaigns/fetchAllCurrentCampaigns';
import fetchAllFinishedCampaignsService from 'services/campaigns/fetchAllFinishedCampaigns';
import fetchExistingCampaignsService from 'services/campaigns/fetchExistingCampaigns';
import fetchCampaignByIdService from 'services/campaigns/fetchCampaignById';
import sendAdCopyService from 'services/campaigns/sendAdCopy';
import fetchCampaignCategoriesService from 'services/campaigns/fetchCampaignCategories';
import fetchCampaignsPendingOffersService from 'services/campaigns/fetchCampaignsPendingOffers';
import fetchCampaignsPodcastsDataService from 'services/campaigns/fetchCampaignsPodcastsData';
import fetchAllEnabledUsersWithFilter from 'services/users/fetchAllEnabledUsersWithFilter';
import editTalkingPointsService from 'services/campaigns/editTalkingPoints';
import fetchAllPendingCampaignsService from 'services/campaigns/fetchAllPendingCampaigns';
import getCampaignReferenceService from 'services/campaigns/createCampaign/campaignReference';

export const createCampaign = ({ campaign }) => async (dispatch, getState) => {
  dispatch(createCampaignInit());

  const { user } = getState().auth;

  try {
    const campaignReference = getCampaignReferenceService({
      brandId: user.uid,
    });

    let campaignImage = '';
    campaignImage = await uploadCampaignImageService({
      campaignImage: campaign.image,
      brandId: user.uid,
      campaignId: campaignReference.id,
    });

    const newCampaign = {
      ...campaign,
      airingFrom: Timestamp.fromDate(campaign.airingFrom),
      airingTo: Timestamp.fromDate(campaign.airingTo),
      createdAt: serverTimestamp(),
      networks: user.networks || [],
      image: campaignImage,
    };

    await createCampaignService({
      campaignReference,
      campaign: newCampaign,
    });

    return dispatch(
      createCampaignSuccess({
        campaign: { ...campaign, uid: campaignReference.id },
      })
    );
  } catch (error) {
    return dispatch(createCampaignFail({ error: error.message }));
  }
};

export const deleteCampaign = (campaignId) => async (dispatch, getState) => {
  dispatch(deleteCampaignInit());

  const { user } = getState().auth;

  try {
    await deleteCampaignService({ brandId: user.uid, campaignId });
    return dispatch(deleteCampaignSuccess({ campaignId }));
  } catch (error) {
    return dispatch(deleteCampaignFail({ error: error.message }));
  }
};

export const editCampaign = ({ campaignId, campaign }) => async (
  dispatch,
  getState
) => {
  dispatch(editCampaignInit());

  const { user } = getState().auth;

  const editedCampaign = {
    ...campaign,
    airingFrom: Timestamp.fromDate(campaign.airingFrom),
    airingTo: Timestamp.fromDate(campaign.airingTo),
  };

  try {
    await editCampaignService({
      brandId: user.uid,
      campaignId,
      campaign: editedCampaign,
    });

    return dispatch(editCampaignSuccess());
  } catch (error) {
    return dispatch(editCampaignFail({ error: error.message }));
  }
};

export const fetchUnfinishedCampaign = () => async (dispatch, getState) => {
  dispatch(fetchUnfinishedCampaignInit());

  const { user } = getState().auth;

  try {
    const campaign = await fetchUnfinishedCampaignService({
      brandId: user.uid,
    });

    let transformedCampaign = null;

    if (campaign) {
      transformedCampaign = {
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      };
    }

    return dispatch(
      fetchUnfinishedCampaignSuccess({ campaign: transformedCampaign })
    );
  } catch (error) {
    return dispatch(fetchUnfinishedCampaignFail({ error: error.message }));
  }
};

export const addOffers = ({ campaignId, offers }) => async (
  dispatch,
  getState
) => {
  dispatch(addOffersInit());

  const { user } = getState().auth;

  try {
    await addOffersService({ brandId: user.uid, campaignId, offers });

    return dispatch(addOffersSuccess(offers));
  } catch (error) {
    return dispatch(addOffersFail({ error: error.message }));
  }
};

export const finishedCampaign = ({ campaignId }) => async (
  dispatch,
  getState
) => {
  dispatch(finishedCampaignInit());

  const { user } = getState().auth;

  try {
    await finishedCampaignService({ campaignId, brandId: user.uid });

    return dispatch(finishedCampaignSuccess());
  } catch (error) {
    return dispatch(finishedCampaignFail({ error: error.message }));
  }
};

export const fetchAllPendingCampaigns = (
  lastDoc,
  searchValue,
  campaigns = [],
  selectedSort = {}
) => async (dispatch, getState) => {
  dispatch(fetchAllPendingCampaignsInit());

  const { user } = getState().auth;

  try {
    const data = await fetchAllPendingCampaignsService({
      brandId: user.uid,
      lastDoc,
      searchValue,
      selectedSort,
    });

    let transformedCampaigns = null;

    if (data) {
      transformedCampaigns = data.pendingCampaigns.map((campaign) => ({
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      }));
    }
    return dispatch(
      fetchAllPendingCampaignsSuccess({
        campaigns: campaigns.concat(transformedCampaigns),
        lastDoc: data.lastDoc,
      })
    );
  } catch (error) {
    return dispatch(fetchAllPendingCampaignsFail({ error: error.message }));
  }
};

export const fetchAllCurrentCampaigns = (
  lastDoc,
  searchValue,
  campaigns = [],
  selectedSort
) => async (dispatch, getState) => {
  dispatch(fetchAllCurrentCampaignsInit());
  const { user } = getState().auth;

  try {
    const data = await fetchAllCurrentCampaignsService({
      brandId: user.uid,
      lastDoc,
      searchValue,
      selectedSort,
    });

    let transformedCampaigns = null;

    if (data) {
      transformedCampaigns = data.currentCampaigns.map((campaign) => ({
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      }));
    }
    return dispatch(
      fetchAllCurrentCampaignsSuccess({
        campaigns: campaigns.concat(transformedCampaigns),
        lastDoc: data.lastDoc,
      })
    );
  } catch (error) {
    return dispatch(fetchAllCurrentCampaignsFail({ error: error.message }));
  }
};

export const fetchAllFinishedCampaigns = (
  lastDoc,
  searchValue,
  campaigns = [],
  selectedSort
) => async (dispatch, getState) => {
  dispatch(fetchAllFinishedCampaignsInit());

  const { user } = getState().auth;

  try {
    const data = await fetchAllFinishedCampaignsService({
      brandId: user.uid,
      lastDoc,
      searchValue,
      selectedSort,
    });

    let transformedCampaigns = null;

    if (data.finishCampaigns) {
      transformedCampaigns = data.finishCampaigns.map((campaign) => ({
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      }));
    }
    return dispatch(
      fetchAllFinishedCampaignsSuccess({
        campaigns: campaigns.concat(transformedCampaigns),
        lastDoc: data.lastDoc,
      })
    );
  } catch (error) {
    return dispatch(fetchAllFinishedCampaignsFail({ error: error.message }));
  }
};

export const fetchExistingCampaigns = () => async (dispatch, getState) => {
  dispatch(fetchExistingCampaignsInit());

  const { user } = getState().auth;

  try {
    const campaigns = await fetchExistingCampaignsService({
      brandId: user.uid,
    });

    let transformedCampaigns = null;

    if (campaigns) {
      transformedCampaigns = campaigns.map((campaign) => ({
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      }));
    }

    return dispatch(
      fetchExistingCampaignsSuccess({ campaigns: transformedCampaigns })
    );
  } catch (error) {
    return dispatch(fetchExistingCampaignsFail({ error: error.message }));
  }
};

export const fetchCampaignById = ({ campaignId }) => async (
  dispatch,
  getState
) => {
  dispatch(fetchCampaignByIdInit());

  const { user } = getState().auth;

  try {
    const campaign = await fetchCampaignByIdService({
      brandId: user.uid,
      campaignId,
    });

    let transformedCampaign = null;

    if (campaign) {
      transformedCampaign = {
        ...campaign,
        airingFrom: campaign.airingFrom.toDate(),
        airingTo: campaign.airingTo.toDate(),
      };
    }

    return dispatch(
      fetchCampaignByIdSuccess({ campaign: transformedCampaign })
    );
  } catch (error) {
    return dispatch(fetchCampaignByIdFail({ error: error.message }));
  }
};

export const sendAdCopy = ({
  campaign,
  podcastId,
  adCopy,
  addToAll = false,
}) => async (dispatch, getState) => {
  dispatch(sendAdCopyInit());

  const { user } = getState().auth;

  try {
    await sendAdCopyService({
      brandId: user.uid,
      campaign,
      podcastId,
      adCopy,
      addToAll,
    });

    return dispatch(sendAdCopySuccess());
  } catch (error) {
    return dispatch(sendAdCopyFail({ error: error.message }));
  }
};

export const editTalkingPoints = ({
  campaign,
  podcastId,
  talkingPoints,
  addToAll = false,
}) => async (dispatch, getState) => {
  dispatch(editTalkingPointsInit());
  const { user } = getState().auth;
  try {
    await editTalkingPointsService({
      brandId: user.uid,
      campaign,
      podcastId,
      talkingPoints,
      addToAll,
    });
    return dispatch(editTalkingPointsSuccess());
  } catch (error) {
    return dispatch(editTalkingPointsFail({ error: error.message }));
  }
};

export const clearTalkingPointsState = () => clearTalkingPoints();

export const fetchCampaignCategories = () => async (dispatch) => {
  dispatch(fetchCampaignCategoriesInit());

  try {
    const categories = await fetchCampaignCategoriesService();

    return dispatch(fetchCampaignCategoriesSuccess({ categories }));
  } catch (error) {
    return dispatch(fetchCampaignCategoriesFail({ error: error.message }));
  }
};

export const fetchCampaignsPendingOffers = ({
  campaigns,
  defaultValues,
}) => async (dispatch) => {
  dispatch(fetchCampaignsPendingOffersInit());

  try {
    const pendingOffers = await fetchCampaignsPendingOffersService({
      campaigns,
      defaultValues,
    });

    return dispatch(fetchCampaignsPendingOffersSuccess({ pendingOffers }));
  } catch (error) {
    return dispatch(fetchCampaignsPendingOffersFail({ error: error.message }));
  }
};

export const fetchCampaignsPodcastsData = ({ podcastsIds }) => async (
  dispatch
) => {
  dispatch(fetchCampaignsPodcastsDataInit());

  try {
    const podcastsData = await fetchCampaignsPodcastsDataService(podcastsIds);

    return dispatch(fetchCampaignsPodcastsDataSuccess({ podcastsData }));
  } catch (error) {
    return dispatch(fetchCampaignsPodcastsDataFail({ error: error.message }));
  }
};

export const fetchNewCampaignEstimations = (args = {}) => async (
  dispatch,
  getState
) => {
  dispatch(fetchNewCampaignEstimationsInit());

  const {
    podcasts: localPodcasts = null,
    filters: newFilters = {},
    localFilter,
  } = args;

  const filters = {
    ...(getState().campaigns.estimationsSummary?.filters || {}),
    ...newFilters,
  };

  const { user } = getState().auth;

  const allLocalPodcasts =
    localPodcasts ??
    getState().campaigns.estimationsSummary?.allLocalPodcasts ??
    [];

  try {
    const podcastsData = await fetchAllEnabledUsersWithFilter(
      {
        podcasts: allLocalPodcasts,
        ...filters,
      },
      localFilter,
      user.networks[0]
    );

    let estimatedImpressions = 0;

    const podcasts = podcastsData.map((doc) => {
      const podcast = doc.data?.() || doc;

      const { podcastData } = podcast;

      if (podcastData) {
        const { audienceEstimate } = podcastData;
        if (audienceEstimate) {
          estimatedImpressions += Number(audienceEstimate);
        }
      }

      return podcast;
    });

    const estimationsSummary = {
      potentialPodcasts: podcastsData.length,
      estimatedImpressions,
      ...(!localFilter && { allLocalPodcasts: podcasts }),
      podcasts,
      filters: newFilters,
    };

    return dispatch(fetchNewCampaignEstimationsSuccess({ estimationsSummary }));
  } catch (error) {
    return dispatch(fetchNewCampaignEstimationsFail({ error: error.message }));
  }
};

export const clearCampaignState = () => clearCampaign();

export const clearEstimationsFiltersState = ({
  filters,
  reEstimate,
  localFilter,
} = {}) => (dispatch) => {
  dispatch(clearEstimationsFilters({ filtersToClear: filters }));

  if (reEstimate) {
    dispatch(fetchNewCampaignEstimations({ localFilter }));
  }
};

export const updateCampaignsTabAction = (tab) => updateCampaignsTab({ tab });
